<template></template>

<script>
import {
  defineComponent,
  onMounted,
  nextTick,
  onBeforeUnmount,
} from "@vue/composition-api"
export default defineComponent({
  name: "DevHelper",
  setup(_, { emit }) {
    if (process.env.NODE_ENV !== "development") {
      console.log("DevHelper component is only available in development mode")
      return {}
    }

    const keypressListener = (e) => {
      if (e.key === "*") {
        emit("update:render", false)
        nextTick(() => {
          emit("update:render", true)
        })
      }
    }

    onMounted(() => {
      window.addEventListener("keydown", keypressListener)
    })

    onBeforeUnmount(() => {
      console.log("DevHelper component unmounted")
      window.removeEventListener("keydown", keypressListener)
    })

    return {}
  },
})
</script>
