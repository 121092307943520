import _ from 'lodash'
import { getWeekdayOccurrence } from './date-utils'
import moment from 'moment'
import { isObjectNotEmpty } from './object-util'
import { capital } from 'case'
import { decompressProcess } from './process-blocks'
import { measurePerformance } from './performance'
import store from '@/store'

export const TASK_DUE_DATE_DISPLAY_FORMAT = 'ddd MMM D YYYY'

export const resolveTaskStatusVariant = (status) => {
  if (status === 'NotStarted') return 'secondary'
  if (status === 'Complete') return 'success'
  if (status === 'InProgress') return 'info'
  if (status === 'Exported') return 'warning'
  return 'primary'
}

export const removeExtraFieldsFromTask = (t) => {
  const task = {};
  ['id', 'handle', 'init_handle', 'name', 'description', 'processId', 'userId', 'assigneeId', 'dueDate', 'status', 'deleted', 'isManuallyCreated', 'created_at'].forEach(f => (task[f] = t[f]))

  if (typeof t.dueDate !== 'string') {
    task.dueDate = task.dueDate.format('YYYY-MM-DD')
  }

  return task
}

export const generateTaskHandle = (process, user, dueDate) => `${process.id}-${user.id}-${dueDate.format('YYYY-MM-DD')}`

export const formatProcessesForTaskGeneration = (processes) => {
  console.time('processes clone deep')
  const processesClone = _.cloneDeep(processes)
  console.timeEnd('processes clone deep')

  console.time('processes filter')
  const filtered = processesClone.filter(p => p.compactFrequency)
  console.timeEnd('processes filter')

  let decompressTime = 0, hashingTime = 0;

  console.time('process formatting')
  const formatted = filtered.map((process, idx) => {
    let time = measurePerformance()
    process.frequency = decompressProcess(process.compactFrequency)
    decompressTime += parseFloat(time())

    time = measurePerformance()
    Object.keys(process.frequency).forEach((freqKey) => {
      if (!process.frequency[freqKey] || !process.frequency[freqKey].days) return;

      process.frequency[freqKey].daysHash = _.mapValues(_.keyBy(process.frequency[freqKey].days), () => true)

      Object.keys(process.frequency[freqKey]).forEach((monthKey) => {
        if (monthKey === 'days' || monthKey === 'daysHash') return;

        process.frequency[freqKey][monthKey] = _.mapValues(_.keyBy(process.frequency[freqKey][monthKey]), () => true)
      })
    })

    hashingTime += parseFloat(time())

    return process
  })
  console.log('decompress time', decompressTime)
  console.log('hashing time', hashingTime)
  console.timeEnd('process formatting')

  return formatted
}

export const shouldTaskBeGenerated = (date, process) => {
  const processFrequency = process.frequency

  const dateWeekday = date.format('ddd').toLowerCase();
  const currentMonthFrequency = processFrequency[date.format('MMM')];
  const interval = processFrequency.Interval;

  if (currentMonthFrequency) {

    // Check if specific day is listed in the month's days hash
    if (currentMonthFrequency.daysHash && currentMonthFrequency.daysHash[date.date()]) return true;

    // Check if process should run on specific weekday or all weekdays ('*')
    const weekdayObject = currentMonthFrequency[dateWeekday];
    if (weekdayObject && (weekdayObject['*'] || weekdayObject[getWeekdayOccurrence(date)])) return true;
  }

  // Handle weekly interval
  if (interval?.StartOn) {
    const startOn = moment(interval.StartOn).startOf('day');
    const weeksDifference = date.diff(startOn, 'weeks') + 1;

    return (
      date.isSameOrAfter(startOn) &&
      interval.RepeatOn === dateWeekday &&
      weeksDifference % interval.RepeatEvery === 0
    );
  }

  return false;
};

export const isValidTaskFilter = (f) => {
  if (f.startsWith("assignee")) {
    return /^assignee(=|\!=).+$/.test(f)
  }
  if (f.startsWith("status")) {
    return /^status(=|\!=).+$/.test(f)
  }

  return true
}

const today = moment().startOf('day')
const lateStatuses = {
  "NotStarted": true,
  "InProgress": true,
}

export const isTaskLate = task => {
  let dueDate = task.dueDate
  if (typeof dueDate === 'string') {
    dueDate = moment(dueDate)
  }

  return dueDate.isBefore(today) && lateStatuses[task.status]
}

export const formatPersistedTask = t => {
  const dueDate = moment(t.dueDate).startOf('day')
  return { ...t, processHandle: t.process.processHandle, dueDate, is_late: isTaskLate(t) }
}
export const generateFutureTasks = (processes, persistedTasks, startDate, pageSize, filters, lastPageDynamicDisplayTask, tasksToSkip) => {


  const persistedTaskWithIdx = persistedTasks.map((p, idx) => ({ ...p, idx }));
  const persistedTasksHash = _.keyBy(persistedTaskWithIdx, t => t.init_handle.split('_')[0])

  // assignee filter
  const excludedUsers = {}
  const includedUsers = {}

  // status filter
  const excludedStatus = {}
  const includedStatus = {}

  const textSearchQueries = []
  let dateRangeFilter = {}
  let hasNextPage = true
  filters.forEach(f => {
    // [_, assignee or status, operator (!= | =), value]
    const matches = f.match(/^(assignee|status)(!?=)(.+)$/)
    if (matches) {
      const [_, key, operator, value] = matches;

      if (key === "status") {
        if (operator === '=') {
          includedStatus[value] = true;
        } else {
          excludedStatus[value] = true;
        }
      } else if (key === "assignee") {
        if (operator === '=') {
          includedUsers[value] = true;
        } else {
          excludedUsers[value] = true;
        }
      }
    }
    else if (f.startsWith('date=')) {
      const parts = f.split('=')[1]
      const [start, end] = parts.split(' to ')
      dateRangeFilter = {
        startDate: moment(start),
        endDate: moment(end)
      }
    } else if (!f.startsWith('is:') && !f.startsWith('isNot:') && f !== 'all:tasks') {
      textSearchQueries.push(new RegExp(`${f}`, 'i'))
    }
  })

  const shouldExcludeUsers = isObjectNotEmpty(excludedUsers)
  const shouldIncludeUsers = isObjectNotEmpty(includedUsers)

  const shouldExcludeStatus = isObjectNotEmpty(excludedStatus)
  const shouldIncludeStatus = isObjectNotEmpty(includedStatus)

  if (filters.some(appliedFilter => ({
    'is:complete': true,
    'is:late': true,
    'is:deleted': true,
  })[appliedFilter]) || (shouldIncludeStatus && !includedStatus['Not Started']) || (shouldExcludeStatus && excludedStatus['Not Started'])) {
    return {
      tasks: persistedTasks.map(formatPersistedTask),
      dynamicTasks: [],
      tasksUsedFromDB: persistedTasks.length
    }
  }

  const shouldntSkip = filters.some(f => f === 'all:tasks') || !filters.length

  let anchorDate = (lastPageDynamicDisplayTask?.dueDate || dateRangeFilter?.startDate || startDate).clone().startOf('day')

  // Don't allow anchor date to be in the past
  if (anchorDate.isBefore(today)) {
    anchorDate = startDate.clone().startOf('day')
  }


  const maxIterations = 500 || 365 * pageSize
  let dynamicTasks = [];
  let i = 0;

  // ------------------- TEXT SEARCH -------------------------
  let filteredProcesses = _.cloneDeep(processes);

  // Apply text search if queries are present
  if (textSearchQueries.length) {
    const searchableColumns = ['processHandle', 'name'];
    filteredProcesses = processes.filter(process =>
      textSearchQueries.some(query =>
        searchableColumns.some(column => query.test(process[column]))
      )
    );
  }

  // Filter users based on inclusion/exclusion criteria
  if (shouldIncludeUsers || shouldExcludeStatus || shouldExcludeUsers || shouldIncludeStatus) {
    filteredProcesses = filteredProcesses.map(process => {
      process.users = process.users.filter(user =>
        (!shouldIncludeUsers || includedUsers[user.name]) &&
        (!shouldExcludeStatus || !excludedStatus[user.name]) &&
        (!shouldExcludeUsers || !excludedUsers[user.name]) &&
        (!shouldIncludeStatus || !includedStatus[user.name])
      );
      return process;
    });
  }
  // ------------------- END TEXT SEARCH ---------------------


  while (i < maxIterations && dynamicTasks.length < pageSize) {
    if (dateRangeFilter.endDate && anchorDate.isAfter(dateRangeFilter.endDate)) {
      hasNextPage = false
      break
    };
    let j = 0;
    filteredProcesses.forEach((process, idx) => {
      // Stop generating new tasks
      if (dynamicTasks.length === pageSize || i >= maxIterations) return;

      // Skip process if shouldn't be generated
      if (!shouldTaskBeGenerated(anchorDate, process)) return;



      process.users.forEach((user, idx2) => {
        j++
        if (dynamicTasks.length === pageSize || i >= maxIterations) return;
        if (lastPageDynamicDisplayTask && j <= lastPageDynamicDisplayTask.j && lastPageDynamicDisplayTask.dueDate.isSame(anchorDate)) return


        const handle = generateTaskHandle(process, user, anchorDate)
        if (tasksToSkip[handle] && !shouldntSkip) return;

        const persistedTask = persistedTasksHash[handle]
        const persistedDueDate = persistedTask && moment(persistedTask.dueDate).startOf('day')

        const task = {
          process,
          processId: process.id,
          processHandle: process.processHandle,
          name: process.name,
          userId: user.id,
          assignee: user,
          assigneeName: user.name,
          status: "NotStarted",
          statusFormatted: "Not started",
          init_handle: handle,
          handle,
          isDynamic: true,

          ...(persistedTask),

          j,
          dueDate: persistedDueDate || anchorDate.clone(),
          is_late: persistedTask ? isTaskLate(persistedTask) : false
        }


        if (persistedTask) {
          delete persistedTasksHash[handle]
        }

        dynamicTasks.push(task)
      })
    })
    anchorDate.add(1, 'day')
    i++
  }

  const formattedDBTasks = Object.values(persistedTasksHash).map(formatPersistedTask)
  const tasks = dynamicTasks.concat(formattedDBTasks).sort((a, b) => a.dueDate.isBefore(b.dueDate) ? -1 : 1).slice(0, pageSize)
  return { tasks, dynamicTasks, tasksUsedFromDB: tasks.filter(t => !!t.id).length, hasNextPage: hasNextPage && tasks.length === pageSize }
};

export const compareTaskHandles = (a, b) => {
  return a.split('_')[0] === b.split('_')[0]
}