import { gql } from 'graphql-tag'

export const TasksToSkipFields = gql`
  fragment TasksToSkipFields on Task {
    init_handle
    id
    status
    deleted
  }
`;

export const TaskNodeFields = gql`
  fragment TaskNodeFields on Task {
    id
    handle
    init_handle
    name
    description
    processId
    userId
    dueDate
    status
    is_late
    deleted
    updated_at
    assignee {
      name
      id
    }
    process {
      name
      processHandle
    }
  }
`;