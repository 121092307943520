
const NUMBER_TO_MONTH_HASH = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const NUMBER_TO_WEEKDAY_HASH = {
  1: "mon",
  2: "tue",
  3: "wed",
  4: "thu",
  5: "fri",
  6: "sat",
  7: "sun",
};

const rangeToNumbers = (string) => string.split(',').reduce((acc, n) => {
  if (!n.includes('-')) {
    acc.push(parseInt(n))
  } else {
    const [firstNum, secondNum] = n.split('-')
    for (let i = firstNum; i <= secondNum; i++) {
      acc.push(parseInt(i))
    }
  }


  return acc
}, [])

export const decompressProcess = (s) => {
  const decompressed = {}
  const [monthly, weekly] = s.split('|')

  if (monthly) {
    const dailyParts = monthly.split(';')

    dailyParts.forEach(part => {
      const [monthNumbers, recurrence] = part.split(':')
      const months = rangeToNumbers(monthNumbers)
      const recParts = recurrence.split('/')
      let days = []
      const weekdays = {}

      recParts.forEach(part => {
        if (part.startsWith('d')) {
          days = rangeToNumbers(part.slice(1))
          return
        }

        // weekly
        const weekdayParts = part.slice(1).split('+')
        weekdayParts.forEach(part => {
          const [weekday, range] = part.split('>')
          weekdays[NUMBER_TO_WEEKDAY_HASH[weekday]] = []
          if (range.startsWith('*')) {
            weekdays[NUMBER_TO_WEEKDAY_HASH[weekday]].push('*')
            return
          }

          if (range.includes('l')) {
            weekdays[NUMBER_TO_WEEKDAY_HASH[weekday]].push('l')
          }

          rangeToNumbers(range.replace('*', '').replace('l', '')).forEach(n => {
            if (!n) return
            weekdays[NUMBER_TO_WEEKDAY_HASH[weekday]].push(n)
          })
        })
      })

      months.forEach(m => {
        decompressed[NUMBER_TO_MONTH_HASH[m]] = {
          days,
          ...weekdays
        }
      })
    })
  }

  if (weekly) {
    const [StartOn, RepeatOn, RepeatEvery] = weekly.split(';')

    decompressed.Interval = {
      StartOn,
      RepeatOn,
      RepeatEvery,
    }
  }


  return decompressed
}