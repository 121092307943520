import gql from 'graphql-tag'
import Vue from 'vue'
import { apolloClient } from '@/libs/vue-apollo'
import * as moment from 'moment-timezone'

export default {
  namespaced: true,
  state: {
    organizationSettings: null,
    isLoading: false,
  },
  getters: {
    tenantMoment() {
      return moment().utcOffset(this.organizationSettings.timezone.replace('GMT', ''))
    },
    tenantToday() {
      return this.tenantMoment.startOf('day')
    }
  },
  mutations: {
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    SET_SETTINGS(state, val) {
      Vue.set(state, 'organizationSettings', val)
    },
  },
  actions: {
    async getSettings({ commit }) {
      commit('SET_LOADING', true)
      try {
        const response = await apolloClient.query({
          query: gql`
            query settings {
              settings {
                organizationName
                timezone
                isEmailAuthEnabled
                isGoogleAuthEnabled
                isMicrosoftAuthEnabled
                sendDailyRemindersForLateTasksViaSMS
                sendWeeklyRemindersViaEmail
              }
            }
          `,
        })
        commit('SET_SETTINGS', response.data.settings)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchSettingsIfNotLoaded({ dispatch, state }) {
      if (!state.organizationSettings) {
        dispatch('getSettings')
      }
    },
    async updateSettings({ commit }, payload) {
      commit('app/TOGGLE_LOADING', true, { root: true })
      return apolloClient.mutate({
        mutation: gql`
            mutation updateSettings($input: UpdateSettingsInput!) {
              updateSettings(updateSettingsInput: $input) {
                organizationName
                timezone
                isEmailAuthEnabled
                isGoogleAuthEnabled
                isMicrosoftAuthEnabled
                sendDailyRemindersForLateTasksViaSMS
                sendWeeklyRemindersViaEmail
                errors
              }
            }
          `,
        variables: {
          input: payload,
        },
      }).then(res => {
        commit('SET_SETTINGS', res.data.updateSettings)
        return res.data.updateSettings
      }).finally(() => {
        commit('app/TOGGLE_LOADING', false, { root: true })
      })
    },
  },
}
