import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue"

export default {
  methods: {
    showErrorToast(error, autoHideDelay = 5000) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: error,
          autoHideDelay
        },
      })
    },
    showSuccessToast(error, autoHideDelay = 5000) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: error, autoHideDelay
        },
      })
    }
  }
}