export const isObjectEmpty = (obj) => Object.entries(obj).length === 0
export const isObjectNotEmpty = (obj) => Object.entries(obj).length !== 0
export const areArraysEqual = (arr1, arr2) => {
  // Check if lengths are equal
  if (arr1.length !== arr2.length) return false;

  // Sort both arrays and compare each item
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export const findInReverse = (array, condition) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (condition(array[i], i, array)) {
      return array[i];
    }
  }
  return undefined;
}

export const mapInReverse = (array, callback) => {
  const result = [];
  for (let i = array.length - 1; i >= 0; i--) {
    result.push(callback(array[i], i, array));
  }
  return result.reverse();
};

export const xLog = (...args) => console.log(JSON.parse(JSON.stringify(args)))

export const sortToAlpha = (arr, key) => {
  if (!arr.length) return []

  const isObjectArray = typeof arr[0] === "object" && key in arr[0];

  return arr.toSorted((a, b) => {
    const getValue = (item) => isObjectArray ? item[key] : item;
    if (typeof getValue(a) !== "string" || typeof getValue(b) !== "string") {
      throw new Error("Array must contain strings or objects with the specified key.");
    }
    return getValue(a).toLowerCase().localeCompare(getValue(b).toLowerCase());
  });
};
